<template>
  <el-dialog
    title="修改密码"
    class="zp-dialog"
    :visible="value"
    :close-on-click-modal="false"
    @update:visible="(visible) => $emit('input', visible)"
  >
    <el-form ref="form" :model="form" label-width="80px" size="small" :rules="rules">
      <el-form-item label="原密码" prop="oldPasswd">
        <el-input v-model="form.oldPasswd" type="password" :minlength="6" :maxlength="18" placeholder="请输入原密码" />
      </el-form-item>

      <el-form-item label="新密码" prop="newPasswd">
        <el-input v-model="form.newPasswd" type="password" :minlength="6" :maxlength="18" placeholder="请输入新密码" />
      </el-form-item>

      <el-form-item label="确认密码" prop="confirmPasswd">
        <el-input
          v-model="form.confirmPasswd"
          type="password"
          :minlength="6"
          :maxlength="18"
          placeholder="请输入确认密码"
        />
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="$emit('input', false)">取 消</el-button>
      <el-button :loading="isSubmitting" type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { userLogout, changePassword } from '@/api/user';

export default {
  name: 'PasswdDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSubmitting: false,
      form: {
        oldPasswd: '',
        newPasswd: '',
        confirmPasswd: '',
      },
      rules: {
        oldPasswd: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          {
            min: 6,
            max: 18,
            message: '密码长度为6到18个字符',
            trigger: 'blur',
          },
        ],
        newPasswd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 6,
            max: 18,
            message: '密码长度为6到18个字符',
            trigger: 'blur',
          },
        ],
        confirmPasswd: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          {
            min: 6,
            max: 18,
            message: '密码长度为6到18个字符',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.newPasswd) {
                callback(new Error('两次输入密码不一致'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    handleSubmit() {
      this.isSubmitting = true;
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          this.isSubmitting = false;
          return false;
        }
        try {
          await changePassword({
            opwd: this.form.oldPasswd,
            npwd: this.form.newPasswd,
          });
          this.$message.success('密码修改成功');
          // 退出登录并清除本地登录数据
          await userLogout();
          // 删除本地登录标识数据
          localStorage.removeItem(ZP.param.sysParam.token);
          this.isSubmitting = false;
          this.$router.push({ name: 'login' });
        } catch (err) {
          ZP.messageError(err.message);
          this.isSubmitting = false;
        }
      });
    },
  },
};
</script>

<style lang="less" module>
@import '@/styles/vars.less';
</style>
