<template>
  <div id="app">
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {};
  },
  watch: {
    $route() {
      ZP.setPageTitle(this.$route.meta.title || '广园汇管理平台');
    },
  },
  mounted() {},
  methods: {},
};
</script>
