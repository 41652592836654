/*
 * @Author: www
 * @Date: 2018-08-19 17:41:34
 * @Last Modified by: chenxiaoxong
 * @Last Modified time: 2023-01-04 16:39:56
 */

export const reg = {
  pwd: /^\w{6,25}$/,
  num: /^(\d{1,})$/,
  nonnegative: /^[0-9]+([.]{1}[0-9]+){0,1}$/, // 非负数（整数、小数、0）
  inviteCode: /^\w{8}$/,
  phone: /^1[0-9]{10}$/,
  landline: /^(0[0-9]{2,3}-?)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/,
  idCard:
    /(^\d{6}\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{3}$)|(^\d{6}(18|19|20|21)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d{3}(\d|X|x)$)/,
  // name: /^([\u4e00-\u9fa5]*\w*){0,25}$/, // 汉字 数字 字母 下划线
  abnormalChar: /[^\u4e00-\u9fa5\w]/, // 非汉字,数字,字母,下划线
  nonChinese: /[^\u4e00-\u9fa5]\w*/, // 非汉字
  shopName: /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/, // 中文，英文，数字，下划线
  numberTwelvePlace: /^[0-9]{12}$/, // 校验开户行联行号 12位数字
  numberTenPlaceAbove: /^[0-9]{5,}$/, // 校验银行卡号 5位数字以上
  noneChinese: /^[^\u4e00-\u9fa5]+$/, // 全非中文
  chinese: /^[\u4e00-\u9fa5]+$/, // 必须中文
  // businessLicense: /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/, // 营业执照号正则验证
  taxCode: /^[a-zA-Z0-9]{15}$|^[a-zA-Z0-9]{18}$|^[a-zA-Z0-9]{20}$/, // 验证税号
  pinYin: /^([A-Za-z]{1,})$/,
  positiveInteger: /^[1-9]\d*$/, // 正整数
  pinYinAndNum: /^([A-Za-z0-9]{1,})$/,
  colorOX: /^([A-Fa-f0-9]{6,6})$/, // 色值
  url: /^https?:\/\//,
  bannerUrl: /^(http[s]{0,1}):\/\/[^\s]*$/,
  contact: /^[\s\-0-9]{1,20}$/, // 校验联系方式，手机和座机
  price: /^(([1-9]\d*)|(0))(\.\d{1,2})?$/, // 正整数、正小数(1-2位精度)
  naturalNumber: /^(([1-9]\d*)|(0))$/, // 自然数：0或正整数
  version: /^([1-9]\d|[1-9])((\.{0,1})([1-9]\d|\d)){0,2}$/, // 版本号校验
  capitalLetter: /^[A-Z]$/, // 大写字母
  replaceDraw: /(\:|\：)+/g, // 替换图号
};
