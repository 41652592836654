// 侧边菜单栏展开与收起状态
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

const getInitState = () => ({
  showSidebar: false,
});

const mutations = {
  [TOGGLE_SIDEBAR](state) {
    state.showSidebar = !state.showSidebar;
  },
};

const getters = {
  showSidebar(state) {
    return state.showSidebar;
  },
};

export default {
  name: 'ui',
  namespaced: true,
  state: getInitState(),
  mutations,
  getters,
};
