/**
 * 用户登录
 * @param {any} data
 * @returns {Promise}
 */
export function login(data) {
  return ZP.Ajax({
    url: '/manage/login/login',
    method: 'post',
    data,
  });
}

/**
 * 退出登录
 * @returns {Promise}
 */
export function userLogout() {
  return ZP.Ajax({
    url: '/manage/login/logout',
  });
}

/**
 * 修改密码
 * @param {any} data
 * @returns {Promise}
 */
export function changePassword(data) {
  return ZP.Ajax({
    url: '/manage/login/updatePwd',
    method: 'post',
    data,
  });
}

/**
 * 获取用户信息
 * @returns {Promise}
 */
export function getUserInfo() {
  return ZP.Ajax({
    url: '/manage/login/info',
  });
}
