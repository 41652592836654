<template>
  <el-menu
    :default-active="$route.path"
    class="menu scroll-bar-better"
    text-color="#5c5f66"
    active-text-color="#057bde"
    :collapse="isCollapse"
    @select="handleSelect"
  >
    <template v-for="menu in menus">
      <el-menu-item v-if="!menu.children.length" :key="menu.id" :index="`/${menu.path}`">
        <svg :class="$style.icon" class="zp-icon" aria-hidden="true">
          <use :xlink:href="menu.icon" />
        </svg>
        <span slot="title">{{ menu.name }}</span>
      </el-menu-item>
      <el-submenu v-else :key="menu.id" :index="`/${menu.path}`">
        <template slot="title">
          <svg :class="$style.icon" class="zp-icon" aria-hidden="true">
            <use :xlink:href="menu.icon" />
          </svg>
          <span slot="title">{{ menu.name }}</span>
        </template>
        <el-menu-item v-for="subMenu in menu.children" :key="subMenu.id" :index="`/${menu.path}/${subMenu.path}`">
          {{ subMenu.name }}
        </el-menu-item>
      </el-submenu>
    </template>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import storeUi from '@/store/modules/ui';
import storePermission from '@/store/modules/permission';

export default {
  name: 'MainNav',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(storeUi.name, {
      isCollapse: 'showSidebar',
    }),
    ...mapGetters(storePermission.name, ['menus']),
  },
  methods: {
    handleSelect(path) {
      this.$router.push({ path });
    },
  },
};
</script>

<style lang="less" module>
@import '@/styles/vars.less';

.icon {
  width: 24px;
  margin-right: 4px;
  font-size: 20px;
}

:global {
  .menu {
    flex-shrink: 0;
    box-sizing: border-box;
    height: 100%;
    padding: 8px 0;
    overflow-x: hidden;
    overflow-y: auto;
    user-select: none;
  }

  .menu:not(.el-menu--collapse) {
    width: 200px;
  }

  .el-menu-item {
    &.is-active,
    &:focus,
    &:hover {
      background-color: #f0f7fd;
    }
  }

  .el-menu-item,
  .el-submenu__title {
    display: flex;
    align-items: center;
    height: 44px;
    line-height: 44px;
  }

  .el-submenu {
    .el-menu-item {
      height: 44px;
      padding-right: 30px;
      line-height: 44px;
    }

    &.is-active {
      .el-submenu__title {
        color: @g-theme-color;

        i {
          color: @g-theme-color;
        }
      }
    }
  }

  .el-submenu__icon-arrow {
    font-size: 16px;
  }
}
</style>
