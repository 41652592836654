import fetch from 'isomorphic-fetch';
import { serialize, filterInvalidData } from './util';

/**
 * 自定义错误类
 * @class FetchError
 * @extends {Error}
 */
class FetchError extends Error {
  constructor(error) {
    // console.log(error);
    super();
    if (typeof error === 'string') {
      this.message = error;
    } else {
      this.code = error.code;
      this.message = error.message || error.msg;
      this.data = error.data;
      // this.stack = error.stack;
    }
  }
}

/**
 * Ajax请求封装
 * @description 对调用者抛出异常信息方式: 1. 错误类 2. return Project.reject('***')
 * @param {any} { url, method = 'get', data: {}/[], type }
 * @param method = get|post|put|delete
 * @returns {Promise}
 */
export function Ajax({ url, method = 'get', data = {}, type }) {
  // 是否绝对url地址
  const isFullUrl = /^https?:\/\//.test(url);
  const isPost = ['post', 'put'].includes(method);
  if (!navigator.onLine) {
    throw new FetchError('网络异常');
  }

  // 请求头配置
  const headers = {
    // 客户端标志
    'X-SOURCE': 'WEB',
  };
  if (type !== 'file') {
    headers['Content-Type'] = isPost ? 'application/json' : 'application/x-www-form-urlencoded';
  }

  // 请求参数
  const options = {
    url: isFullUrl ? url : `${ENV_CONFIG.url}${url}?t=${Date.now()}`,
    method,
    headers,
    credentials: 'include',
  };

  // 参数格式化处理
  if (type === 'file') {
    if (Array.isArray(data)) {
      throw new FetchError(new Error('请求类型与参数不匹配'));
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    options.body = formData;
  } else if (['get', 'delete'].includes(method)) {
    if (Array.isArray(data)) {
      throw new FetchError(new Error('请求方法与参数不匹配'));
    }
    const param = serialize(data);
    options.url += param ? `&${param}` : '';
  } else {
    if (Array.isArray(data)) {
      options.body = JSON.stringify(data);
    } else {
      // 过滤无效数据
      options.body = JSON.stringify(filterInvalidData(data));
    }
  }

  return fetch(options.url, options)
    .then((response) => {
      // fetch响应处理
      if (!response.ok) {
        // return Promise.reject(response.statusText);
        throw new FetchError({
          code: response.status,
          message: response.statusText,
        });
      }
      return response.json();
    })
    .then((res) => {
      // 状态码处理
      if (res.code === 200) {
        // 成功状态
        return res.data;
      }
      if (res.code === 100004 || res.code === 100009) {
        // 100004-无操作权限 100009-未登录（登录超时）
        ZP.quitSystem();
      }
      throw new FetchError(res);
    })
    .catch((error) => {
      throw new FetchError(error);
    });
}

/**
 * 导出表格文件
 * @param {any} { url, data = {} }
 */
export function ExportExcel({ url = '', data = {} }) {
  if (!url) {
    throw new Error('缺少必传参数');
  }
  let exportUrl = `${ENV_CONFIG.url}${url}?t=${Date.now()}`;
  const param = serialize(data);
  exportUrl += param ? `&${param}` : '';
  location.assign(exportUrl);
}
