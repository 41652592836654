import { cloneDeep } from 'lodash';
import { getPermissionList } from '@/api/permission';

export const ADD_PERMISSION = 'ADD_PERMISSION';
export const RESET = 'RESET';

const initState = {
  permissions: [], // 用户权限
};

const mutations = {
  [ADD_PERMISSION](state, payload) {
    state.permissions = payload;
  },
  [RESET](state) {
    Object.assign(state, cloneDeep(initState));
  },
};

const actions = {
  async fetchPermission({ commit }) {
    const permissions = (await getPermissionList()) || [];
    // 格式化菜单数据再提交
    commit(ADD_PERMISSION, [
      ...permissions.map((menu) => ({
        id: menu.id,
        name: menu.name,
        path: menu.path,
        icon: menu.icon,
        children: menu.child.map((subMenu) => ({
          id: subMenu.id,
          name: subMenu.name,
          path: subMenu.path,
        })),
      })),
    ]);
  },
};

const getters = {
  menus(state) {
    return state.permissions;
  },
};

export default {
  name: 'permission',
  namespaced: true,
  state: cloneDeep(initState),
  mutations,
  actions,
  getters,
};
