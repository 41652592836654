/**
 * 文件字节换算为使用单位
 * @param {number} byte
 * @returns {string}
 */
export function getFileSize(byte) {
  const UNIT = {
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };
  if (byte < UNIT.KB) {
    return `${byte}B`;
  } else if (byte >= UNIT.KB && byte < UNIT.MB) {
    return ((byte / UNIT.KB).toFixed(2) * 100) / 100 + 'KB';
  } else if (byte >= UNIT.MB && byte < UNIT.GB) {
    return ((byte / UNIT.MB).toFixed(2) * 100) / 100 + 'MB';
  } else if (byte >= UNIT.GB && byte < UNIT.TB) {
    return ((byte / UNIT.GB).toFixed(2) * 100) / 100 + 'GB';
  } else {
    return ((byte / UNIT.TB).toFixed(2) * 100) / 100 + 'TB';
  }
}

/**
 * 获取本地上传图片文件的宽高
 * @param {any} file
 * * @returns {Object}
 */
export function getImageWidthHeight(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (ev) => {
        const image = new Image();
        image.onload = () => {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.src = ev.target.result;
      };
      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * 获取图片文件的base64编码
 * @param {any} file
 * @returns {Promise}
 */
export function getImageBase64(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (ev) => {
        resolve(ev.target.result);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * 利用canvas压缩Data URLs图片
 * 压缩格式选择优化级: webp(源文件小) > jpeg(虽源文件大过png，但压缩后会变小) > png
 * @param {string} dataURL
 * @param {number} [width=0]  指定压缩宽度, 0-不压缩宽度
 * @param {number} [quality=0.8] 图片压缩比例(0 < x <=1)
 * 不精确的压缩测试
 * png无效果
 * jpeg quality=0.8时为大小的1/3; quality:0.6时为原大小1/4
 * webp quality=0.8时为大小的1/3; quality:0.6时为原大小1/4
 */
export function canvasCompressImage({ dataURL, width = 0, quality = 0.8 } = {}) {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        const originWidth = img.width;
        const originHeight = img.height;
        console.log('origin width-height', originWidth, originHeight);
        let targetWidth = 0;
        let targetHeight = 0;
        if (width === 0 || Math.max(originWidth, originHeight) <= width) {
          targetWidth = originWidth;
          targetHeight = originHeight;
        } else if (originWidth > originHeight) {
          targetWidth = width;
          targetHeight = Math.ceil((width * originHeight) / originWidth);
        } else {
          targetWidth = Math.ceil((width * originWidth) / originHeight);
          targetHeight = width;
        }
        console.log(`canvas width: ${targetWidth}, canvas height: ${targetHeight}`);
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL('image/jpeg', quality));
      };
      img.src = dataURL;
    } catch (err) {
      reject(err);
    }
  });
}

/**
 * 获取图片文件信息
 * @param {any} file
 * @returns  {Promise} 图片宽高和base64 url
 */
export function getImageFileInfo(file) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (ev) => {
        const image = new Image();
        image.onload = () => {
          resolve({
            width: image.width,
            height: image.height,
            base64Url: ev.target.result,
            file,
          });
        };
        image.src = ev.target.result;
      };
      reader.readAsDataURL(file);
    } catch (err) {
      reject(err);
    }
  });
}
