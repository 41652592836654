/**
 * 转换小10的数字为二个位数的字符串数字，填充前置0
 * @param {any} num
 * @returns {String}
 */
function fillZero(num) {
  return num < 10 ? '0' + num : num;
}

/**
 * 获取月份对应的天数
 * 日期中日参数: 0或负数表示上个月倒数的日期（0-上个月最后一天 -1-上个月倒数第二天）
 * @param {any} year
 * @param {any} month
 * @returns {Number}
 */
export function getDaysOfMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

// 获取当前月份的上一个月份的日期
export function getPrevMonth() {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  return new Date(year, month - 1);
}

// 时间范围：最近周
export function getLastWeekRange() {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return [new Date(year, month, day - 6).getTime(), new Date(year, month, day, 23, 59, 59).getTime()];
}

// 时间范围：最近月
export function getLastMonthRange() {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  // 如果当前日期的天值 >= 上个月的总天数时
  if (day >= getDaysOfMonth(year, month - 1)) {
    return [new Date(year, month, 1).getTime(), new Date(year, month, day, 23, 59, 59).getTime()];
  }
  return [new Date(year, month - 1, day + 1).getTime(), new Date(year, month, day, 23, 59, 59).getTime()];
}

// 时间范围：当前时间向前推一年
export function getLastYearRange() {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return [new Date(year - 1, month, day + 1).getTime(), new Date(year, month, day, 23, 59, 59).getTime()];
}

/**
 * 获取倒计时：天、时、分、秒
 * @param {any} timestamp 时间戳
 * @returns {String}
 */
export function getDaysCountdown(timestamp) {
  if (!timestamp) {
    console.error('请提供时间戳参数');
    return '';
  }
  // 获取时间差秒数
  const timeDiff = parseInt((timestamp - Date.now()) / 1000);
  if (timeDiff < 0) {
    console.log(timeDiff);
    console.error('时间戳参数不能小于当前时间戳');
    return '';
  }
  const days = parseInt(timeDiff / 3600 / 24);
  const hours = parseInt((timeDiff / 3600) % 24);
  const minutes = parseInt((timeDiff / 60) % 60);
  const seconds = parseInt(timeDiff % 60);
  return `${days}天${fillZero(hours)}小时${fillZero(minutes)}分${fillZero(seconds)}秒`;
}
