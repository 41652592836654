<template>
  <div class="layout">
    <Topbar :user-info="userInfo" />
    <div class="layout-container">
      <MainNav />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import storeUser from '@/store/modules/user.js';
import storePermission from '@/store/modules/permission.js';

import Topbar from './topbar';
import MainNav from './main-nav';

export default {
  name: 'Layout',
  components: {
    Topbar,
    MainNav,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(storeUser.name, ['userInfo']),
  },
  created() {
    // 获取并初始化用户信息
    this.fetchUser();
    // 获取并初始化用户权限
    this.fetchPermission();
  },
  methods: {
    ...mapActions(storeUser.name, ['fetchUser']),
    ...mapActions(storePermission.name, ['fetchPermission']),
  },
};
</script>
