var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.$style.topbar },
    [
      _c("div", { class: _vm.$style.logo, on: { click: _vm.handleToIndex } }, [
        _c("img", {
          attrs: {
            src: require("@/images/logo@2x.png"),
            width: "36",
            alt: "广园汇管理平台"
          }
        }),
        _vm._v(" "),
        _c("h1", [_vm._v("广园汇管理平台")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.$style.userBox,
          on: {
            mouseenter: function($event) {
              _vm.showDropMenu = true
            },
            mouseleave: function($event) {
              _vm.showDropMenu = false
            }
          }
        },
        [
          _c("div", { class: _vm.$style.user }, [
            _c("h3", { staticClass: "single-ellipsis" }, [
              _vm._v(
                _vm._s(_vm.welcomeText) + "，" + _vm._s(_vm.userInfo.realname)
              )
            ]),
            _vm._v(" "),
            _c(
              "svg",
              { staticClass: "zp-icon", attrs: { "aria-hidden": "true" } },
              [_c("use", { attrs: { "xlink:href": "#icon-icon-jiantouxia" } })]
            )
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDropMenu,
                    expression: "showDropMenu"
                  }
                ],
                class: _vm.$style.dropMenu
              },
              [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: {
                        click: function($event) {
                          _vm.showModifyPassword = true
                        }
                      }
                    },
                    [_vm._v("修改密码")]
                  )
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: { click: _vm.handleLogout }
                    },
                    [_vm._v("退出登录")]
                  )
                ])
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("PasswdDialog", {
        model: {
          value: _vm.showModifyPassword,
          callback: function($$v) {
            _vm.showModifyPassword = $$v
          },
          expression: "showModifyPassword"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }