import isSupportWebp from './webp';

// 日期格式化
// new Date(val).format('yyyy-MM-dd hh:mm:ss');
Date.prototype.format = function (str = 'yyyy-MM-dd hh:mm:ss') {
  let format = str;
  const o = {
    'M+': this.getMonth() + 1, // month
    'd+': this.getDate(), // day
    'h+': this.getHours(), // hour
    'm+': this.getMinutes(), // minute
    's+': this.getSeconds(), // second
    'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
    S: this.getMilliseconds(), // millisecond
  };

  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length));
  }

  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
    }
  });
  return format;
};

// 初始化时判断当前环境是否支持webp图片格式
(() => {
  try {
    const storeIsSupportWebp = JSON.parse(localStorage.getItem('isSupportWebp'));
    // 未设置时值为null
    if (storeIsSupportWebp === null) {
      const isWebp = isSupportWebp();
      localStorage.setItem('isSupportWebp', isWebp);
      window.isSupportWebp = isWebp;
    } else {
      window.isSupportWebp = storeIsSupportWebp;
    }
  } catch (err) {
    console.log(err.message);
  }
})();
