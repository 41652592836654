import { serialize } from './util';
/**
 * 获取当前页面模块路径
 * @returns {String} 模块路径名称
 */
export function getPageModule() {
  return location.pathname || '/';
}

/**
 * 设置页面模块的本地数据
 * @param {String} page
 * @param {Object} data
 */
export function setPageData(page, data) {
  if (page && data) {
    localStorage.setItem(page, JSON.stringify(data));
  }
}

/**
 * 获取页面模块的本地数据
 * @param {String} page
 * @returns {Object}
 */
export function getPageData(page) {
  try {
    return JSON.parse(localStorage.getItem(page)) || {};
  } catch (err) {
    return {};
  }
}

/**
 * 删除页面模块的本地数据
 * @param {any} page
 */
export function removePageData(page) {
  localStorage.removeItem(page);
}

/**
 * 解析url的search参数，转换成对象
 * @export getPageUrlSearchParam
 * @param {any} [url=location.search] - 可传入默认参数进行解析
 * @returns {Object}
 */
export function getPageUrlSearchParam(url = location.search) {
  const reg = /[?&]([^&=]*)=([^&]*)/gi;
  const searchParam = {};
  while (reg.exec(url)) {
    searchParam[RegExp.$1] = window.decodeURIComponent(RegExp.$2);
  }
  return searchParam;
}

/**
 * 设置页面标题
 * @export setPageTitle
 * @param {string} [title='']
 */
export function setPageTitle(title = '') {
  document.title = title;
}

/**
 * 页面跳转方法
 * @param {any} [{ path, data, urlShowFlag = false, storageFlag = false, replaceFlag = false, isOpen = false }={}]
 * @param { String } path 目标路径
 * @param { Object } data 目标页面参数
 * @param { Boolean } urlShowFlag 是否将data序列化显示在url上
 * @param { Boolean } storageFlag 是否本地存储data
 * @param { Boolean } replaceFlag 页面跳转时是否生成历史记录
 * @param { Boolean } isOpen 是否新窗口打开
 */
export function routeTo({
  path,
  data,
  urlShowFlag = true,
  storageFlag = false,
  replaceFlag = false,
  isOpen = false,
} = {}) {
  if (!path) {
    console.log('缺少必传参数page');
    return;
  }
  if (storageFlag) {
    setPageData(path, data);
  }
  let url = '';
  if (path && data && urlShowFlag) {
    const param = serialize(data);
    url = param ? `${path}?${serialize(data)}&t=${Date.now()}` : `${path}?t=${Date.now()}`;
  } else if (path) {
    url = `${path}?t=${Date.now()}`;
  }
  if (isOpen) {
    window.open(url);
  } else if (replaceFlag) {
    location.replace(url);
  } else {
    location.href = url;
  }
}
