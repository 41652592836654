import router from '@/router';

/**
 * 获取用户是否登录
 * @returns {Boolean}
 */
export function isLogin() {
  return !!localStorage.getItem(ZP.param.sysParam.token);
}

/**
 * 判断用户是否在线
 * @return {Boolean} 在线情况
 */
export function isOnline() {
  return navigator.onLine;
}

/**
 * 返回客户端类型
 * @return {String} 客户端类型
 */
export function getClientType() {
  return 'WEB';
}

/**
 * 接口状态码处理弹窗
 */
export function quitSystem() {
  localStorage.removeItem(ZP.param.sysParam.token);
  router.replace({
    name: 'login',
    query: {
      redirect: location.href.replace(location.origin, ''),
    },
  });
}

/**
 * 错误消息提示封装
 */
export const messageError = ((errMsg) => {
  let instance = null;
  return (errMsg) => {
    if (!instance) {
      instance = ZP.Message({
        type: 'error',
        message: errMsg,
        customClass: 'zp-message-error',
        onClose: () => {
          instance = null;
        },
      });
    }
  };
})();

/**
 * 判断是否Firefox内核浏览器
 * @export {Boolean}
 */
export function isFirefox() {
  return navigator.userAgent.includes('Firefox');
}
