// mock system menus
const menus = [
  { id: 1, name: '首页', path: 'index', icon: '#icon-icon-shouye-mian', buttons: [], child: [] },
  {
    id: 2,
    name: '用户管理',
    path: 'user-manage',
    icon: '#icon-icon-yonghuguanli-mian',
    buttons: [],
    child: [
      { id: 21, name: '用户列表', path: 'user-list', buttons: [] },
      { id: 22, name: '用户审核', path: 'user-audit-list', buttons: [] },
      { id: 23, name: '用户子账号', path: 'user-subaccount-list', buttons: [] },
    ],
  },
  {
    id: 3,
    name: '内容管理',
    path: 'content-manage',
    icon: '#icon-icon-neirongguanli',
    buttons: [],
    child: [{ id: 31, name: 'banner管理', path: 'banner-list', buttons: [] }],
  },
  {
    id: 4,
    name: '平台商品管理',
    path: 'platform-goods-manage',
    icon: '#icon-icon-pingtaishangpinguanli',
    buttons: [],
    child: [
      { id: 41, name: '平台商品数据库', path: 'platform-goods-list', buttons: [] },
      { id: 42, name: '产品分类管理', path: 'product-classify-list', buttons: [] },
      { id: 43, name: '产品品牌管理', path: 'product-brand-list', buttons: [] },
      { id: 44, name: '可替代管理', path: 'replaceable-manage', buttons: [] },
      { id: 45, name: '汽车品牌管理', path: 'car-brand-list', buttons: [] },
      { id: 46, name: '车型管理', path: 'car-model-list', buttons: [] },
      { id: 47, name: '专项配置管理', path: 'special-config-list', buttons: [] },
    ],
  },
  {
    id: 5,
    name: '员工管理',
    path: 'staff-manage',
    icon: '#icon-icon-yuangongguanli',
    buttons: [],
    child: [{ id: 51, name: '平台子账号', path: 'platform-subaccount-list', buttons: [] }],
  },
  {
    id: 6,
    name: '系统管理',
    path: 'system-manage',
    icon: '#icon-icon-xitongguanli',
    buttons: [],
    child: [
      { id: 61, name: '菜单管理', path: 'menu-list', buttons: [] },
      { id: 62, name: '协议配置', path: 'protocol-list', buttons: [] },
      { id: 63, name: '短信管理', path: 'sms-list', buttons: [] },
    ],
  },
];

/**
 * 获取菜单
 * @returns {Promise}
 */
export function getPermissionList() {
  return ZP.Ajax({
    url: '/manage/permission/menu/list',
    data: {
      platformId: 1,
      currentPage: 1,
      pageSize: 1000,
    },
  });
  // return Promise.resolve(menus);
}
