/*
 * @Author: www
 * @Date: 2019-08-15 19:47:44
 * @Last Modified by: www
 * @Last Modified time: 2019-08-16 13:30:24
 */

/**
 * IM统一处理
 * 1. 用户在平台登录后初始化IM
 * 2. 平台中点击IM聊天目标时会话初始化
 */

import { cloneDeep } from 'lodash';

export const UPDATE_CONVERSATION_TARGET = 'UPDATE_CONVERSATION_TARGET';
export const UPDATE_SHOW_STATUS = 'UPDATE_SHOW_STATUS';

const initState = {
  targetId: null,
  showIm: false,
};

const mutations = {
  [UPDATE_CONVERSATION_TARGET](state, targetId) {
    state.targetId = targetId;
  },
  [UPDATE_SHOW_STATUS](state, isShow) {
    state.showIm = isShow;
  },
};

const getters = {
  targetId(state) {
    return state.targetId;
  },
  showIm(state) {
    return state.showIm;
  },
};

export default {
  name: 'im',
  namespaced: true,
  state: cloneDeep(initState),
  mutations,
  getters,
};
