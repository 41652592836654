<template>
  <div :class="$style.topbar">
    <div :class="$style.logo" @click="handleToIndex">
      <img src="@/images/logo@2x.png" width="36" alt="广园汇管理平台" />
      <h1>广园汇管理平台</h1>
    </div>
    <div :class="$style.userBox" @mouseenter="showDropMenu = true" @mouseleave="showDropMenu = false">
      <div :class="$style.user">
        <h3 class="single-ellipsis">{{ welcomeText }}，{{ userInfo.realname }}</h3>
        <svg class="zp-icon" aria-hidden="true">
          <use xlink:href="#icon-icon-jiantouxia"></use>
        </svg>
      </div>
      <transition name="el-fade-in-linear">
        <ul v-show="showDropMenu" :class="$style.dropMenu">
          <li>
            <a href="javascript:;" @click="showModifyPassword = true">修改密码</a>
          </li>
          <li>
            <a href="javascript:;" @click="handleLogout">退出登录</a>
          </li>
        </ul>
      </transition>
    </div>
    <PasswdDialog v-model="showModifyPassword" />
  </div>
</template>

<script>
import { userLogout } from '@/api/user';
import PasswdDialog from '@/components/passwd-dialog';

export default {
  name: 'Topbar',
  components: {
    PasswdDialog,
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDropMenu: false,
      showModifyPassword: false,
    };
  },
  computed: {
    welcomeText() {
      const hours = new Date().getHours();
      let text = '';
      if (hours < 6) {
        text = '凌晨好';
      } else if (hours < 9) {
        text = '早上好';
      } else if (hours < 12) {
        text = '上午好';
      } else if (hours < 14) {
        text = '中午好';
      } else if (hours < 17) {
        text = '下午好';
      } else {
        text = '晚上好';
      }
      return text;
    },
  },
  methods: {
    handleToIndex() {
      this.$router.push('/');
    },
    async handleLogout() {
      try {
        await this.$confirm('确定要退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        // 退出登录
        await userLogout();
        // 清除本地登录数据
        localStorage.removeItem(ZP.param.sysParam.token);
        this.$router.replace({ name: 'login' });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less" module>
@import '@/styles/vars.less';

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
  padding: 0 24px 0 32px;
  color: @g-white;
  background-color: @g-theme-color;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    border-radius: 50%;
    background-color: white;
  }

  > h1 {
    margin-left: 6px;
    font-size: 14px;
    font-weight: @g-font-weight-bold;
  }
}

.user-box {
  position: relative;
  z-index: 1000;
}

.user {
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;

  > h3 {
    max-width: 92px;
    padding: 8px 0;
    margin-right: 4px;
    line-height: 20px;
  }
}

.drop-menu {
  position: absolute;
  top: 36px;
  right: 0;
  width: 112px;
  padding: 10px 0;
  border-radius: @g-radius-xlg;
  background-color: @g-white;
  font-size: 14px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    color: @g-text-regular-color;

    &:hover {
      color: @g-theme-color;
    }
  }
}
</style>
