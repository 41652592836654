import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/components/layout';

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = PAGE_LIST.map((name) => {
  // 从视图的配置文件中读取信息
  const { title = '', route = {} } = require(`@/views/${name}/config.js`);
  if (!route.children) {
    // 一级路由
    return {
      name,
      path: `/${route.path}`,
      component: () => import(`@/views/${name}/index.vue`),
      meta: { title, route },
    };
  } else {
    // 二级路由：嵌套布局组件
    return {
      path: `/${route.path}`,
      component: Layout,
      children: [
        {
          name,
          path: route.children.path,
          component: () => import(`@/views/${name}/index.vue`),
          meta: { title, route },
        },
      ],
    };
  }
});

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes: [
    ...routes,
    {
      path: '*',
      redirect: {
        path: '/index',
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

/**
 * router错误处理
 * 1. Loading chunk {n} failed
 */
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    // const targetPath = router.history.pending.fullPath;
    // router.replace(targetPath);
    // 重新加载页面，避免上述方法死循环
    location.reload();
  }
});

// 全局前置守卫
router.beforeEach((to, from, next) => {
  if (!ZP.isLogin() && to.name !== 'login') {
    return next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  } else if (ZP.isLogin() && to.name === 'login') {
    return next({
      path: '/index',
    });
  }
  next();
});

export default router;
