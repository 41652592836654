import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import ElementUI from 'element-ui';

// 全局样式
import '@/styles/global.less';

// 自定义图标symbol
import '@/libs/iconfont.js';

// 全局初始化
import '@/utils/init.js';

// 挂载工具方法到window.ZP下
import '@/utils/index.js';

// router 配置
import router from './router';

// vuex配置
import store from './store';

import App from './App';

// 在vuex中同步router状态
sync(store, router);

// 注册全局ui组件
Vue.use(ElementUI);

// 配置是否允许vue-devtools检查代码
// Vue.config.devtools = ENV_CONFIG.env === 'development';
Vue.config.devtools = true;

console.log('webpack注入的变量');
console.log('node-env: ', process.env.NODE_ENV);
// console.log('pagelist: ', PAGE_LIST);
console.log('env config:', ENV_CONFIG);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
