import { cloneDeep } from 'lodash';
import { getUserInfo } from '@/api/user.js';

export const ADD_USER = 'ADD_USER';
export const UPDATE_PORTRAIT = 'UPDATE_PORTRAIT';
export const RESET_USER = 'RESET_USER';

const initState = {
  parentId: '', // 主id
  id: '', // 用户id
  type: 1, // 账号类型: 1-管理后台 2-pc、xcx
  phone: '', // 账号
  nickname: '', // 昵称
  realname: '', // 名称(兼容子账号)
  headImgurl: '', // 头像
};

const mutations = {
  [ADD_USER](state, payload) {
    Object.keys(state).forEach((key) => {
      state[key] = payload[key] || state[key];
    });
  },
  [UPDATE_PORTRAIT](state, payload) {
    state.portrait = payload;
  },
  [RESET_USER](state) {
    Object.assign(state, cloneDeep(initState));
  },
};

const actions = {
  async fetchUser({ commit }) {
    const user = await getUserInfo();
    commit(ADD_USER, user);
  },
};

const getters = {
  userInfo(state) {
    return state;
  },
  userId(state) {
    return state.id;
  },
  imUserId(state) {
    return state.imUserId;
  },
};

export default {
  name: 'user',
  namespaced: true,
  state: cloneDeep(initState),
  mutations,
  actions,
  getters,
};
